import { useState } from "react";
import useForm from "../../hooks/useForm";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const Newsletter = () => {

  let lang = i18next.language;

  const { i18n } = useTranslation(["form", "errors"]);
  const { values, handleChange, handleSubmit } = useForm(enviarDados);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  function enviarDados() {

    const locale = {
      locale: lang,
      country: lang
    };

    const formData = {...values,...locale};
    
    setLoading(true);

    (async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}forms/subscribe`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
          }
        );

        const data = await response.json();

        setLoading(false);

        if (data.status === "error") {
          //setErrors(data.errors);
          console.log(data);
          return;
        }

        setSuccess(true);

      } catch (error) {
        console.error(error);
      }
    })();
  }

  return (
    <>
      <h4 className="widget-title">{i18n.t("common:newsletter_title")}</h4>
      <form className="subscription-form validate" onSubmit={handleSubmit}>
        <div className="input-group flex-nowrap">
          <i className="ai-mail position-absolute top-50 translate-middle-y text-muted fs-base ms-3"></i>

          <input
            type="text"
            name="email"
            className="form-control rounded-start"
            placeholder="O seu email"
            onChange={handleChange}
            disabled={loading || success}
            required
          />

          <button
            onClick={handleSubmit}
            className="btn btn-primary"
            disabled={loading || success}
          >
            {i18n.t("common:subscrever")}
          </button>
        </div>

        <div className="form-text mt-3">
          {success ?  i18n.t("common:newletter_success") : i18n.t("common:newsletter")}
          </div>
        <div className="subscription-status"></div>
      </form>
    </>
  );
};

export default Newsletter;
