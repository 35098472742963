import solucoesarmazenagem from "../images/picking7.jpg";
import escadasaluminio from "../images/photo-1593106410245-241ab38175e7.jpg";
import gama from "../images/photo-1565891741441-64926e441838.jpg";

function Cards() {

  return (
    <>
    <h2 className="mb-5 text-center">sobre a <b>Litan</b></h2>
    
      <div className="container">
        <div className="row equal mt-4 mb-5">
          <div className="col-sm d-flex col-lg-4 col-sm-6 text-center">
            <article className="bg-body rounded shadow-sm">
              <img alt=""
                className="mx-auto d-block pb-2 img-fluid"
                src={solucoesarmazenagem}
              />

              <p className="p-4 lh-base">
                Uma empresa com mais de <b>20 anos de experiência</b>, em que
                a sua atividade consiste na conceção, fabrico, comercialização
                e prestação de serviços relacionados com as{" "}
                <b>Estantes Metálicas</b>.
              </p>
            </article>
          </div>

          <div className="col-sm d-flex col-lg-4 col-sm-6 text-center">
            <article className="bg-body rounded shadow-sm">
              <img
                alt=""
                className="mx-auto d-block pb-2 img-fluid"
                src={gama}
              />

              <p className="p-4 lh-base">
              Dispomos de uma área de <b>25.000m2</b>, onde se
                trabalha com avançados meios técnicos e recursos humanos de
                grande experiência, controlados por um sistema de qualidade{" "}
                <b>Norma europeia 15512</b> e certificados pela Norma ISO
                9001.
              </p>
            </article>
          </div>

          <div className="col-sm d-flex col-lg-4 col-sm-6 text-center">
            <article className="bg-body rounded shadow-sm">
              <img alt="" className="mx-auto d-block pb-2 img-fluid" src={escadasaluminio} />
              <p className="p-4 lh-base">
                Dispomos de uma gama totalmente integrada e personalizada para
                qualquer sector da <b>industria e serviços</b>, que garantem a
                otimização e maior eficiência no negócio dos nossos clientes
              </p>
            </article>
          </div>
        </div>
      </div>
      </>
  );
}

export default Cards;
