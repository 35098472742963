import React, {Suspense} from "react";
import "./App.scss";
import "bootstrap-icons/font/bootstrap-icons.css";
import { Routes, Route, Navigate } from "react-router-dom";
import Loader from "./components/loader";
import Menu from "./components/header/menu";
import Navbar from "./components/header/navbar";
import Footer from "./components/footer";
import Cards from "./components/cards";
import Services from "./components/services";
import Actionbar from "./components/actionbar";
import FadeInSection from "./components/fade";

const About = React.lazy(() => import("./routes/about"));
const Home = React.lazy(() => import("./routes/home"));
const News = React.lazy(() => import("./routes/news"));
const NewsArticle = React.lazy(() => import("./routes/news_article"));
const Catalogs = React.lazy(() => import("./routes/catalogs"));
const Contacts = React.lazy(() => import("./routes/contacts"));
const Portfolio = React.lazy(() => import("./routes/portfolio"));
const Products = React.lazy(() => import("./routes/products"));
const Product = React.lazy(() => import("./routes/product"));
const Kits = React.lazy(() => import("./routes/kits"));

const App = () => {

  return (
    <Suspense fallback={<Loader />}>
      <Navbar />
      <Menu />
      <Routes>
        <Route path={`:lang/`} exact element={<Home />} />
        <Route path={`:lang/quem-somos`} element={<About />} />
        <Route path={`:lang/noticias`}>
          <Route path="" element={<News />} />
          <Route path=":slug" element={<NewsArticle />} />
        </Route>
        <Route path={`:lang/portfolio`} element={<Portfolio />} />
        <Route path={`:lang/catalogos`} element={<Catalogs />} />
        <Route path={`:lang/contactos`} element={<Contacts />} />
        <Route path={`:lang/kits`}>
          <Route path="" element={<Kits />} />
          <Route path=":slug" element={<Product />} />
        </Route> 
        <Route path={`:lang/:slug`}>
          <Route path="" element={<Products />} />
          <Route path=":slug" element={<Product />} />
        </Route>
        <Route path="*" element={<Navigate to={`:lang/`} />} />
      </Routes>
      <FadeInSection><Actionbar /></FadeInSection>
      <FadeInSection><Services /></FadeInSection>
      <FadeInSection><Cards /></FadeInSection>
      <Footer />
    </Suspense>
  );
}

export default App;
