import { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useBodyClass from "./BodyClass";
import Nav from "./nav";
import logo from "../../images/Logo-Litan.png";

const Hamburger = () => {

  let location = useLocation();

  const { i18n } = useTranslation();

  useEffect(() => {
    //fecha o menu se mudarmos de link
    toogleMenu();
  }, [location]);

  const [showMenu, toogleMenu] = useState(false);

  const handleChange = () => {
    toogleMenu(showMenu ? false : true);
  };

  //add open or close tag to body
  useBodyClass(showMenu ? "hamburger-menu-open" : "hamburger-menu-close");

  return (
    <>
      <div className="hamburger-btn d-block d-md-none mb-3" onClick={handleChange}>
        <button className="btn btn-outline-secondary">
          {" "}
          {showMenu ? (
            <i className="bi bi-x-lg"></i>
          ) : (
            <i className="bi bi-list"></i>
          )}
        </button>
      </div>

      <div className={(showMenu ? "show" : "") + " hamburger collapse"}>
        <Link className="d-block" to={`${i18n.language}/`}><img className="logo d-block mx-auto" src={logo} alt="" /></Link>
        <Nav />
      </div>
    </>
  );
};

export default Hamburger;
