import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import download from "../images/icons/download.svg";
import operator from "../images/icons/operator.svg";
import sketch from "../images/icons/sketch.svg";
import livroreclamacoes from "../images/icons/livro_reclamacoes.svg";

function Services() {

  const { i18n } = useTranslation();

  return (
    <div className="services">
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <div className="bg-body rounded shadow-sm p-3">
              <div className="d-flex justify-content-start align-items-center">
                <img src={sketch} width="30px" className="me-3" alt="" />

                <b> {i18n.t("national_fabric")} </b>
              </div>
              <p className="my-3">{i18n.t("national_development")}</p>
            </div>
            </div>
            <div className="col-md-3">
            <div className="bg-body rounded shadow-sm p-3">
              <div className="d-flex justify-content-start align-items-center">
                <img
                  src={download}
                  alt="Catálogos"
                  width="30px"
                  className="me-3"
                />

                <b>{i18n.t("catalogs")}</b>
              </div>
              <p className="my-3">{i18n.t("catalogs_download")}</p>
              <a
                ui-sref="root.catalogos"
                className="btn btn-outline-danger btn-sm ng-binding"
                href="/catalogos"
              >
                {i18n.t("download")}
              </a>
            </div>
            </div>
        
            <div className="col-md-3">
            <div className="bg-body rounded shadow-sm p-3">
              <div className="d-flex justify-content-start align-items-center">
                <img
                  src={operator}
                  width="30px"
                  alt="contacte-nos"
                  className="me-3"
                />

                <b>{i18n.t("contactus")}</b>
              </div>
              <p className="my-3">
              {i18n.t("form_text")}
              </p>
              
              <Link className="btn btn-outline-danger btn-sm" to={`${i18n.language}/contacts`}>{i18n.t("contact_form")}</Link>
            </div>
          </div>

          <div className="col-md-3">
            <div className="bg-body rounded shadow-sm p-3">
              <div className="d-flex justify-content-start align-items-center">
                <img
                  src={livroreclamacoes}
                  width="30px"
                  className="me-3"
                  alt=""
                />

                <b>Livro de reclamações</b>
              </div>
              <p className="my-3">
                Resolução Alternativa de Conflitos de Consumo
              </p>
              <a
                href="https://www.livroreclamacoes.pt/inicio"
                target="_blank"
                rel="noreferrer"
                className="btn btn-outline-danger btn-sm"
                alt=""
              >
                Livro de reclamações
              </a>
            </div>
            </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
