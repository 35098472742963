import { useTranslation } from "react-i18next";
import certificacao from "../images/900114001IQNET_NEGATIVO.png";
import p2020 from "../images/POCentro_PT2020_FEDER_Bom.png";
import Newsletter from "./footer/newsletter";


function Footer() {

  const { i18n } = useTranslation();

  return (
    <footer className="footer bg-dark pt-5 pt-md-6">
      <div className="container pt-3 pb-0 pt-md-0 pb-md-3">
        <div className="row mb-4">
          <div className="col-md-4">
            <div className="widget widget-light pb-2 mb-4">
              <h4 className="widget-title">{i18n.t('contacts')}</h4>
              <ul>
                <li>
                  <a className="widget-link" href="mailto:support@example.com">
                  <i className="bi bi-envelope"></i> geral@litan.pt
                  </a>
                </li>
                <li>
                  <a className="widget-link" href="tel:9107848015">
                  <i className="bi bi-phone"></i> +351 234 623 490
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-4">
            <div className="widget widget-light pb-2 mb-4">
              <h4 className="widget-title">Links</h4>
              <a
                className="widget-link me-2"
                href="https://litan.pt/pdf/politica_privacidade.pdf"
                target="_blank"
                rel="noreferrer"
              >
                {i18n.t('privacy_policy')}
              </a>
              <a
                className="widget-link me-2"
                href="https://litan.pt/pdf/rgpd-litan.pdf"
                target="_blank"
                rel="noreferrer"
              >
               RGPD
              </a>

            </div>
          </div>
          <div className="col-md-4">
            <div className="widget widget-light pb-3">
              <Newsletter />
            </div>    
          </div>
        </div>

        <div className="row">
        <div className="col-sm-5 col-xs-12 mt-4 mb-3">
          <span className="text-light opacity-50 me-1">
            © Litan - Soluções de Armazenagem Industrial
          </span>
        </div>
        
        <div className="col-sm-4 col-xs-12 mt-4 mb-3">
          <img src={p2020}  className="img-fluid" alt="p2020" />
        </div>
        <div className="col-sm-3 col-xs-12 mt-4 mb-3 text-center">
          <img src={certificacao} width="150px" alt="apcer" />
        </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
