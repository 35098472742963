import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Nav from "./nav";
import Hamburger from "./hamburger";

import Sticky from "./sticky";
import logo from "../../images/Logo-Litan.png";



function Menu() {

  const { i18n } = useTranslation(['home']);

  return (
  <Sticky>
    <div className="container">
      <div className="d-flex align-items-end">
        <div className="me-auto p-2 bd-highlight">
          <Link to={`${i18n.language}/`}><img className="logo me-5" src={logo} alt="" /></Link>
        </div>
        <div className="d-none d-md-block d-xl-block d-xxl-block p-2">
          <Nav />
          
        </div>
        <Hamburger />
      </div>
    </div>
    </Sticky>
  );
}

export default Menu;
