import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Contactbar() {

  const { i18n } = useTranslation();

  return (
    <div className="container">
      <div className="contactbar rounded shadow-sm d-flex justify-content-between">
        <div>
          <h2>
            {i18n.t('solucoes_armazenagem')} | {i18n.t('escadas_aluminio')} 
          </h2>
          <p>
          {i18n.t('form_text')}
          </p>
        </div>
        <div className="button d-flex justify-content-between">
          <Link to={`${i18n.language}/contacts`}>
            <i className="bi bi-envelope-open-fill me-3"></i> {i18n.t('contactus')}
          </Link>
        </div>
      </div>
    </div>
  );
}

