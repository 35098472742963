import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Nav(){

    const { i18n } = useTranslation(['home']);

    return(
        <nav className="nav nav-primary" aria-label="Secondary navigation">
        <ul>
         <li>
         <Link to={`${i18n.language}/quem-somos`} className="nav-link flex-fill">
           {i18n.t("aboutus")}
         </Link>
         </li>
         <li>
         <Link to={`${i18n.language}/estantes_metalicas`} className="nav-link flex-fill">
           {i18n.t("estantes")}
         </Link>
         </li>
         <li>
         <Link to={`${i18n.language}/escadas_em_aluminio`} className="nav-link flex-fill">
          {i18n.t("escadas_aluminio")}
         </Link>
         </li>
         <li>
         <Link to={`${i18n.language}/kits`} className="nav-link flex-fill">
          {i18n.t("Kits")}
         </Link>
         </li>
         <li>
         <Link to={`${i18n.language}/catalogos`} className="nav-link flex-fill">
           {i18n.t("catalogs")}
         </Link>
         </li>
         <li>
         <Link to={`${i18n.language}/contactos`} className="nav-link flex-fill">
           {i18n.t("contacts")}
         </Link>
         </li>
         </ul>
       </nav>
    )
}