import { useState } from "react";
import i18next from "i18next";

const Dropdown = () => {

  let lang = i18next.language;

  const [show, setFixed] = useState(false);

  const languages = [
    { lang: "pt" },
    { lang: "en" },
    { lang: "fr" },
    { lang: "es" },
  ];

  const showMenu = () => {
    setFixed(!show ? true : false);
  };

  let url = window.location.pathname.slice(4);

  return (
    <>
      <div className="lang cursor" onClick={showMenu}>
        {lang && (
          <div>
            <img
              width={"20px"}
              src={require(`../../images/flags/${lang}.png`)}
              alt={lang}
            /> {lang}
          </div>
        )}
      </div>
      <ul className={`dropdown-menu shadow  ${show ? "show" : ""}`}>
       
        {languages.map((item) => (
          <li key={item.lang} className="dropdown-item">
            <a href={`/${item.lang}/${url}`}>
              <img
                width={"20px"}
                src={require(`../../images/flags/${item.lang}.png`)}
                alt={item.lang}
              />
              <span>{item.lang}</span>
            </a>
          </li>
        ))}
      </ul>
    </>
  );
};

export default Dropdown;
