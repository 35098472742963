import { useState } from 'react';

const useForm = (callback) => {

  const [values, setValues] = useState({});

  

  const handleSubmit = (event) => {
    if (event) event.preventDefault();
      callback();
  };

  const handleChange = (event) => {
/*
    const validationStateSchema = {
      company: {
        required: true,
        validator: {
          regEx: /^[a-zA-Z]+$/,
          error: 'Invalid first name format.',
        },
      },
      firstname: {
        required: true,
        validator: {
          regEx: /^[a-zA-Z]+$/,
          error: 'Invalid last name format.',
        },
      }
    };
    
    const name = event.target.name;
    const value = event.target.value;

    console.log(validationStateSchema[name].required)

    let error = '';

    if (validationStateSchema[name].required) {
      if (!value) {
        error = 'This is required field.';
      }
    }

    if (
      validationStateSchema[name].validator !== null &&
      typeof validationStateSchema[name].validator === 'object'
    ) {
      if (value && !validationStateSchema[name].validator.regEx.test(value)) {
        error = validationStateSchema[name].validator.error;
      }
    }

    console.log(error);*/


    event.persist();
    setValues(values => ({ ...values, [event.target.name]: event.target.value }));
  };

  const initValues = (data) => {
    setValues(data);
  }

  return {
    handleChange,
    handleSubmit,
    initValues,
    values,
  }
};

export default useForm;