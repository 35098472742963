import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Dropdown from "./dropdown";
import megaphone from "../../images/icons/megaphone.svg";
import clients from "../../images/icons/clients.svg";

function Navbar() {
  
  const { i18n } = useTranslation();

  return (
    <>
      <div className="nav-scroller">
        <div className="container">
          <nav className="nav nav-underline" aria-label="Secondary navigation">
            <ul className="d-flex bd-highlight">
              <li>
                <Link
                  to={`${i18n.language}/noticias`}
                  className="nav-link flex-fill"
                >
                  <div className="icon"><img src={megaphone} width="17px" alt={i18n.t("news")} /></div>
                  <span className="d-none d-md-block d-xl-block d-xxl-block">{i18n.t("news")}</span>
                </Link>
              </li>
              <li>
                <Link
                  to={`${i18n.language}/portfolio`}
                  className="nav-link flex-fill"
                >
                  <div className="icon"><img src={clients} width="17px" alt="Partners" /></div>
                  <span className="d-none d-md-block d-xl-block d-xxl-block"> {i18n.t("Partners")}</span>
                </Link>
              </li>
              <li>
                <Dropdown />
              </li>
            </ul>
            <ul>
              <li className="pt-1">
                <a className="pill email" href="mailto:geral@litan.pt">
                  <i className="bi bi-envelope px-2"></i>  <span className="d-none d-md-block d-xl-block d-xxl-block">geral@litan.pt</span>
                </a>
              </li>
              <li className="pt-1">
              <a className="pill phone" href="phoneto:+351234623490">
                <i className="bi bi-phone"></i>  <span className="d-none d-md-block d-xl-block d-xxl-block">+351 234 623 490</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
}

export default Navbar;
