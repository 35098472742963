import React, { useLayoutEffect, useState, useRef } from "react";

const Sticky = ({children}) => {

  const ref = useRef();
  const sticky = useStickyHeader( 50 );
  const headerClasses = `header ${(sticky) ? 'sticky' : ''}`

  return (
      <header ref={ref} className={`${headerClasses} shadow-sm`} >{children}</header>
  );
}


function useStickyHeader(offset = 0) {
  const [stick, setStick] = useState(false);
  
  const handleScroll = () => {
    setStick( window.scrollY > offset );
  };
  
  useLayoutEffect(() => {
    window.addEventListener('scroll', handleScroll);
    
    return(() => {
      window.removeEventListener('scroll', handleScroll);
    });
  });
  
  return stick;
}


export default Sticky;